@import "../site/variables-v3";
@import "../site/common-v3";
@import "../site/utilities/icons-v3";
@import "../site/new-homepage/header";
@import "../site/footer-v3";

* {
    text-decoration: none;
}

.press {
    .press-header {
        background-image: url('/img/press/bg-press.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        margin-top: 80px;

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.25);
        }

        &__title {
            text-align: center;
            position: relative;
            z-index: 1;
        }
    }

    .content {
        margin: 20px 0;
        padding: 10px;
        background: white;
        align-items: center;
        border-radius: 6px;
        box-shadow: 0 2px 6px rgba($color-grey-100, 0.08);
        transform: scale(1);
        transition: transform .2s ease;

        @media(max-width: $mobile-break) {
            padding: 12px;
        }

        &:hover {
            transform: scale(1.05);
            transition: transform .2s ease;
        }

        &__img {
            width: 92px;
            height: 92px;
            object-fit: cover;

            @media(max-width: $tablet-break) {
                width: 80px;
                height: 80px;
            }

            @media(max-width: $mobile-break) {
                width: 64px;
                height: 64px;
            }
        }

        &__info-con {
            margin-left: 16px;
        }

        &__subtitle {
            @media(max-width: $mobile-break) {
                margin-bottom: 8px;
                font-size: 14px;
                line-height: 1.5;
            }
        }

        &__press-name {
            &_logo {
                width: auto;
                height: 24px;
                object-fit: contain;
            }
        }
    }

    .pagination {
        & > * {
            color: $color-grey-100;
        }

        &__con {
            display: flex;
            padding-left: 0;
            justify-content: center;
            margin: auto;
        }

        &__page {
            width: 30px;
            height: 30px;
            margin: 0 20px;
            line-height: 30px;
            text-align: center;
            background: transparent;
            border-radius: 50%;
            list-style-type: none;
            cursor: pointer;

            &:hover {
                background: rgba($color-white, 0.5);
            }
        }

        &_number_current {
            background: $color-white;
        }
    }

    .footer {
        background: $color-white;
        padding: 40px 0;

        &__item-con {
            margin: 0;
            padding: 0;
            justify-content: space-between;

            @media(max-width: $tablet-break) {
                flex-wrap: wrap;
                justify-content: space-evenly;
            }
        }

        &__item {
            list-style: none;
        }

        &__logo {
            height: 20px;
            width: auto;
        }
    }
}

.press-profile{
    &__header{
        height: vw(465px);
        width: 100%;
        background-image: url("/img/press/profile-press-bg.jpg");
        background-size: cover;
        background-attachment: fixed;
        padding: 48px 108px;
        @media(max-width: $mobile-break) {
            height: 465px;
            padding: 32px 10px;
        }
        & .title{
            font-weight: 600;
            font-size: vw(24px);
            line-height: vw(33px);
            margin-bottom: vw(65px);
            color: #FFFFFF;
            display: flex;
            flex-direction: column;
            width: max-content;
            text-align: center;

            @media(max-width: $mobile-break) {
                font-size: 18px;
                line-height: 25px;
                margin-bottom: 35px;
                text-align: center;
                width: unset;
            }
        }
        & .text-holder{
            display: flex;
            flex-direction: column;
            align-items: center;
            & .desc{
                font-weight: 600;
                font-size: vw(20px);
                line-height: vw(27px);
                text-align: center;
                color: #FFFFFF;
                margin-bottom: 0;
                @media(max-width: $mobile-break) {
                    font-size: 20px;
                    line-height: 27px;
                }
            }
            & .name{
                font-weight: 600;
                font-size: vw(48px);
                line-height: vw(65px);
                color: #FFFFFF;
                margin: 0;
                @media(max-width: $mobile-break) {
                    font-size: 36px;
                    line-height: 60px;
                }
            }
        }
    }

    & .main-content{
        margin: 0 vw(116px);
        background-color: #FFF;
        position: relative;
        @media(max-width: $mobile-break) {
            margin: 0 10px;
        }
        & .introduction-box{
            width: 100%;
            box-shadow: 0 4px 26px rgba(0, 0, 0, 0.1);
            background-color: #FFF;
            display: flex;
            position: relative;
            height: vw(347px);
            top: vw(-150px);
            flex-direction: row;
            @media(max-width: $mobile-break) {
                min-height: 615px;
                height: auto;
                top: -205px;
                flex-direction: column;
            }
            & > div{
                flex-basis: 50%;
                flex-grow: 0;
                @media(max-width: $mobile-break) {
                    flex-basis: auto;
                }
            }
            & .text-holder{
                padding: vw(37px) vw(28px) vw(57px) vw(37px);
                border-top: 8px solid #2979FF;
                @media(max-width: $mobile-break) {
                    padding: 37px 16px 55px;
                }
                & .desc{
                    font-size: vw(16px);
                    line-height: vw(24px);
                    color: #435160;
                    margin:0;
                    @media(max-width: $mobile-break) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }

            & .img-holder{
                background-image: url("/img/press/sam-mehrbod.jpg");
                background-size: cover;
                padding: vw(28px) vw(50px);
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                position: relative;
                @media(max-width: $mobile-break) {
                    flex-grow: 1;
                    min-height: 150px;
                }
                & .forbes-logo{
                    position: absolute;
                    height: vw(117px);
                    width: vw(117px);
                    top: vw(28px);
                    @media(max-width: $mobile-break) {
                        height: 117px;
                        width: 117px;
                        top: -38px;
                    }
                }
                & .desc{
                    font-size: vw(18px);
                    line-height: vw(25px);
                    color: #1D3C72;
                    margin:0;
                    @media(max-width: $mobile-break) {
                        font-size: 18px;
                        line-height: 25px;
                        display: none;
                    }
                }
            }
        }

        & .articles-container{
            display: flex;
            flex-direction: column;
            position: relative;
            top: -50px;
            @media(max-width: $mobile-break) {
                top: -150px;
            }
            & .top-row{
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 48px;
                & .desc{
                    font-size: vw(16px);
                    line-height: vw(22px);
                    margin-bottom:8px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #435160;
                    @media(max-width: $mobile-break) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                & .title{
                    font-size: vw(24px);
                    line-height: vw(33px);
                    margin:0;
                    text-align: center;
                    color: #111418;
                    font-weight: 600;
                    @media(max-width: $mobile-break) {
                        font-size: 24px;
                        line-height: 33px;
                    }
                }
            }

            & .press-holder{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @media(max-width: $mobile-break) {
                    flex-direction: column;
                }
                & .press-box-item{
                    background: #FFFFFF;
                    box-shadow: 0 4px 19px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    width: vw(375px);
                    height: vw(358px);
                    margin-right: vw(25px);
                    margin-bottom: vw(25px);
                    padding: 10px;
                    @media(max-width: $mobile-break) {
                        width: 100%;
                        height: 134px;
                        margin-right: 0;
                        margin-bottom: 16px;
                        padding: 16px 8px;
                    }
                    & .logo-holder{
                        margin-bottom: 16px;
                        height: vw(15px);
                        @media(max-width: $mobile-break) {
                            height: 15px;
                        }
                        & .logo{
                            width: auto;
                            height: 100%;
                        }
                    }
                    & .mobile-right-holder{
                        display: flex;
                        flex-direction: column;
                        @media(max-width: $mobile-break) {
                            flex-direction: row;
                        }
                    }
                    & .cover-holder{
                        height: vw(211px);
                        margin-bottom: 16px;
                        @media(max-width: $mobile-break) {
                            margin-bottom: 0;
                            margin-right: 13px;
                            height: 70px;
                            width: 70px;
                            flex-shrink: 0;
                        }
                    }
                    & .cover{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    & .desc{
                        font-size: vw(20px);
                        line-height: vw(27px);
                        margin:0;
                        font-weight: 600;
                        color: #435160;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        @media(max-width: $mobile-break) {
                            font-size: 18px;
                            line-height: 25px;
                            -webkit-line-clamp: 3;
                        }
                    }
                }
                & .press-box-item:nth-child(3n){
                    margin-right: 0;
                }
            }

            & .link-holder{
                display: flex;
                justify-content: center;
                margin-bottom: 48px;
                & a {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: vw(14px);
                    line-height: vw(19px);
                    text-align: center;
                    text-transform: uppercase;
                    color: #1565C0;
                    @media(max-width: $mobile-break) {
                        font-size: 14px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
    &__footer{
        width: 100%;
        height: vw(200px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #202020;
        @media(max-width: $mobile-break) {
            height: 150px;
        }

        & .contact-info {
            display: flex;
            align-items: center;
            color: #FFF;
            font-size: vw(20px);
            line-height: vw(27px);
            margin: 0 0 vw(8px);

            @media(max-width: $mobile-break) {
                max-width: 85%;
                font-size: 16px;
                line-height: 22px;
            }

            & b {
                margin-right: 10px;
            }

            & a {
                color: #FFF;
            }

            & span {
                @media(max-width: $mobile-break) {
                    text-align: center;
                }
            }
        }

        & .contact-info-long {
            @media(max-width: $mobile-break) {
                flex-direction: column;
            }
        }
    }
}